
#list {
  .date-row {
    padding: 10px 0px;
    min-height: 120px;
  }
}

.divider {
  width: 100%;
  border-top: 0.1em solid #000000;
  opacity: 0.1;
}

.todays-date {
  font-size: 0.8em;
  padding: 0 0 0 0.9em;
  font-weight: 300;
  color: #6f6f6f;
}

.past {
  opacity: 0.3;
  transition: 0.3s;

  &:hover {
    opacity: 1;
    transition: 0.5s;
  }
}

.present {
  opacity: 1;

  scroll-margin-top: 8em;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(242, 241, 244, 1) 100%);

  &:hover {
    opacity: 1;
  }
}

.future {
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
    transition: 0.5s;
  }
}

.datenlists {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: columns;

  .datetoday {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50px;
    text-align: center;
    flex-direction: column;
    margin-right: 0.8em;
    .date {
      padding: 5px;
      line-height: 0.95;
      text-align: center;
      h2 {
        font-size: 1.4em;
      }
      h1 {
        font-size: 2em;
        font-weight: 900;
      }
    }
  }

  .lists {
    width: 100%;
  }
}
