
::v-deep .dropdown-menu {
  width: 100%;
}
::v-deep .dropdown-item:hover {
  background: #fff !important;
}

::v-deep .datepicker > .dropdown {
  z-index: 1 !important;
}
